<template>
    <span class="is-flex is-align-items-center is-justify-content-end" v-tooltip="'# of videos captured on the checklist'">
        <icon icon="film" class="has-text-grey index-icon" />
        <span class="ml-3">{{ videosCount }}</span>
    </span>
</template>
<script>
export default {

    props: {
        answers: {
            type: Array,
            default: () => ([])
        }
    },

    computed: {
        videosCount() {
            return this.answers.reduce((total, answer) => total + answer.videos.length, 0)
        }
    }

}
</script>